<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'||showType=='edit'" v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="收入类型" prop="income_type">
          <a-select :disabled="showType=='detail'||showType=='edit'" v-model="formData.income_type">
            <a-select-option value="1">物业管理费</a-select-option>
            <a-select-option value="2">车位管理费</a-select-option>
            <a-select-option value="3">产权车场收入</a-select-option>
            <a-select-option value="4">公共收入</a-select-option>
            <a-select-option value="5">多经收入</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="计划年份" prop="year">
          <a-select :disabled="showType=='detail'||showType=='edit'" v-model="formData.year">
            <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="" prop=""></a-form-model-item>
        <a-form-model-item label="一月" prop="month_one">
          <a-input style="width: 315px" :disabled="currentYear!=formData.year||showType=='detail'||formData.month_one_confirm==='1'||currentMonth===1||currentMonth!==2" v-model.trim="formData.month_one" @blur="formData.month_one=formData.month_one.replace(/[^\w\,]/g,'').toUpperCase()"/>
          <a-button v-if="btnList.includes('确认')&&formData.month_one_confirm!=='1'" style="margin-left: 10px;" type="primary" @click="confirmOperatingStatement(formData.state_id,1)">确认</a-button>
        </a-form-model-item>
        <a-form-model-item label="二月" prop="month_two">
          <a-input style="width: 315px" :disabled="currentYear!=formData.year||showType=='detail'||formData.month_two_confirm==='1'||currentMonth===2||currentMonth!==3" v-model.trim="formData.month_two" @blur="formData.month_two=formData.month_two.replace(/[^\w\,]/g,'').toUpperCase()"/>
          <a-button v-if="btnList.includes('确认')&&formData.month_two_confirm!=='1'" style="margin-left: 10px;" type="primary" @click="confirmOperatingStatement(formData.state_id,2)">确认</a-button>
        </a-form-model-item>
        <a-form-model-item label="三月" prop="month_three">
          <a-input style="width: 315px" :disabled="currentYear!=formData.year||showType=='detail'||formData.month_three_confirm==='1'||currentMonth===3||currentMonth!==4" v-model.trim="formData.month_three" @blur="formData.month_three=formData.month_three.replace(/[^\w\,]/g,'').toUpperCase()"/>
          <a-button v-if="btnList.includes('确认')&&formData.month_three_confirm!=='1'" style="margin-left: 10px;" type="primary" @click="confirmOperatingStatement(formData.state_id,3)">确认</a-button>
        </a-form-model-item>
        <a-form-model-item label="四月" prop="month_four">
          <a-input style="width: 315px" :disabled="currentYear!=formData.year||showType=='detail'||formData.month_four_confirm==='1'||currentMonth===4||currentMonth!==5" v-model.trim="formData.month_four" @blur="formData.month_four=formData.month_four.replace(/[^\w\,]/g,'').toUpperCase()"/>
          <a-button v-if="btnList.includes('确认')&&formData.month_four_confirm!=='1'" style="margin-left: 10px;" type="primary" @click="confirmOperatingStatement(formData.state_id,4)">确认</a-button>
        </a-form-model-item>
        <a-form-model-item label="五月" prop="month_five">
          <a-input style="width: 315px" :disabled="currentYear!=formData.year||showType=='detail'||formData.month_five_confirm==='1'||currentMonth===5||currentMonth!==6" v-model.trim="formData.month_five" @blur="formData.month_five=formData.month_five.replace(/[^\w\,]/g,'').toUpperCase()"/>
          <a-button v-if="btnList.includes('确认')&&formData.month_five_confirm!=='1'" style="margin-left: 10px;" type="primary" @click="confirmOperatingStatement(formData.state_id,5)">确认</a-button>
        </a-form-model-item>
        <a-form-model-item label="六月" prop="month_six">
          <a-input style="width: 315px" :disabled="currentYear!=formData.year||showType=='detail'||formData.month_six_confirm==='1'||currentMonth===6||currentMonth!==7" v-model.trim="formData.month_six" @blur="formData.month_six=formData.month_six.replace(/[^\w\,]/g,'').toUpperCase()"/>
          <a-button v-if="btnList.includes('确认')&&formData.month_six_confirm!=='1'" style="margin-left: 10px;" type="primary" @click="confirmOperatingStatement(formData.state_id,6)">确认</a-button>
        </a-form-model-item>
        <a-form-model-item label="七月" prop="month_seven">
          <a-input style="width: 315px" :disabled="currentYear!=formData.year||showType=='detail'||formData.month_seven_confirm==='1'||currentMonth===7||currentMonth!==8" v-model.trim="formData.month_seven" @blur="formData.month_seven=formData.month_seven.replace(/[^\w\,]/g,'').toUpperCase()"/>
          <a-button v-if="btnList.includes('确认')&&formData.month_seven_confirm!=='1'" style="margin-left: 10px;" type="primary" @click="confirmOperatingStatement(formData.state_id,7)">确认</a-button>
        </a-form-model-item>
        <a-form-model-item label="八月" prop="month_eight">
          <a-input style="width: 315px" :disabled="currentYear!=formData.year||showType=='detail'||formData.month_eight_confirm==='1'||currentMonth===8||currentMonth!==9" v-model.trim="formData.month_eight" @blur="formData.month_eight=formData.month_eight.replace(/[^\w\,]/g,'').toUpperCase()"/>
          <a-button v-if="btnList.includes('确认')&&formData.month_eight_confirm!=='1'" style="margin-left: 10px;" type="primary" @click="confirmOperatingStatement(formData.state_id,8)">确认</a-button>
        </a-form-model-item>
        <a-form-model-item label="九月" prop="month_nine">
          <a-input style="width: 315px" :disabled="currentYear!=formData.year||showType=='detail'||formData.month_nine_confirm==='1'||currentMonth===9||currentMonth!==10" v-model.trim="formData.month_nine" @blur="formData.month_nine=formData.month_nine.replace(/[^\w\,]/g,'').toUpperCase()"/>
          <a-button v-if="btnList.includes('确认')&&formData.month_nine_confirm!=='1'" style="margin-left: 10px;" type="primary" @click="confirmOperatingStatement(formData.state_id,9)">确认</a-button>
        </a-form-model-item>
        <a-form-model-item label="十月" prop="month_ten">
          <a-input style="width: 315px" :disabled="currentYear!=formData.year||showType=='detail'||formData.month_ten_confirm==='1'||currentMonth===10||currentMonth!==11" v-model.trim="formData.month_ten" @blur="formData.month_ten=formData.month_ten.replace(/[^\w\,]/g,'').toUpperCase()"/>
          <a-button v-if="btnList.includes('确认')&&formData.month_ten_confirm!=='1'" style="margin-left: 10px;" type="primary" @click="confirmOperatingStatement(formData.state_id,10)">确认</a-button>
        </a-form-model-item>
        <a-form-model-item label="十一月" prop="month_eleven">
          <a-input style="width: 315px" :disabled="currentYear!=formData.year||showType=='detail'||formData.month_eleven_confirm==='1'||currentMonth===11||currentMonth!==12" v-model.trim="formData.month_eleven" @blur="formData.month_eleven=formData.month_eleven.replace(/[^\w\,]/g,'').toUpperCase()"/>
          <a-button v-if="btnList.includes('确认')&&formData.month_eleven_confirm!=='1'" style="margin-left: 10px;" type="primary" @click="confirmOperatingStatement(formData.state_id,11)">确认</a-button>
        </a-form-model-item>
        <a-form-model-item label="十二月" prop="month_twelve">
          <a-input style="width: 315px" :disabled="(currentYear!=formData.year&&currentMonth!==1)||showType=='detail'||formData.month_twelve_confirm==='1'||currentMonth===12||currentMonth!==1" v-model.trim="formData.month_twelve" @blur="formData.month_twelve=formData.month_twelve.replace(/[^\w\,]/g,'').toUpperCase()"/>
          <a-button v-if="btnList.includes('确认')&&formData.month_twelve_confirm!=='1'" style="margin-left: 10px;" type="primary" @click="confirmOperatingStatement(formData.state_id,12)">确认</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  addOperatingStatement,
  getOperatingStatementListByCondition, getWorkPointListByCondition, getWorkUnitsList, getWorkUnitsListByCondition,
  modifyOperatingStatement
} from "A/businessmanagement";
import {getCache} from "U/index";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      currentMonth: new Date().getMonth() + 1, // 注意，getMonth()返回的月份是从0开始的，所以需要+1
      currentYear: new Date().getFullYear(),
      modalBodyStyle: {
        height: '450px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        state_id:'',
        monitorpointnum:'',
        category:'年度实际收入',
        finance_type:'收入',
        decision_type:'实际',
        income_type:'',
        month_one:'',
        month_two:'',
        month_three:'',
        month_four:'',
        month_five:'',
        month_six:'',
        month_seven:'',
        month_eight:'',
        month_nine:'',
        month_ten:'',
        month_eleven:'',
        month_twelve:'',
        year:'',
        month_one_confirm:'',
        month_two_confirm:'',
        month_three_confirm:'',
        month_four_confirm:'',
        month_five_confirm:'',
        month_six_confirm:'',
        month_seven_confirm:'',
        month_eight_confirm:'',
        month_nine_confirm:'',
        month_ten_confirm:'',
        month_eleven_confirm:'',
        month_twelve_confirm:'',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        income_type: [{required: true, message: '请选择收入类型'}],
        year: [{required: true, message: '请选择计划年份'}],
      },
      monitorpointList:[],
      workPointList:[],
      yearOptions: [],

      //操作按钮权限
      btnList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit'||this.showType == 'paste') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        if(this.detailData){
          this.btnList=this.detailData.btnList
        }
        this.resetData();
        this.getMonitorPointNameList();
        this.getYearOptions();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.state_id) {
        if(this.showType == 'edit' || this.showType == 'detail'||this.showType === 'paste') {
          this.$nextTick(() => {
            let params = {
              state_id: this.detailData.state_id
            }
            getOperatingStatementListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit'|| this.showType=='paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.formData.month_one_confirm=""
            this.formData.month_two_confirm=""
            this.formData.month_three_confirm=""
            this.formData.month_four_confirm=""
            this.formData.month_five_confirm=""
            this.formData.month_six_confirm=""
            this.formData.month_seven_confirm=""
            this.formData.month_eight_confirm=""
            this.formData.month_nine_confirm=""
            this.formData.month_ten_confirm=""
            this.formData.month_eleven_confirm=""
            this.formData.month_twelve_confirm=""
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add') {
              this.showLoading();
              addOperatingStatement(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'|| this.showType=='paste'){
              this.showLoading();
              modifyOperatingStatement(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      for(let i=3; i>-1; i--) {
        this.yearOptions.push(currentYear+i+'');
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    confirmOperatingStatement(state_id,month){
      this.$confirm('您要确认该计划吗？计划确认后将无法再进行修改！',{
        type:'warning',
        centered:true,
      }).then(()=> {
        if (month === 1) {
          modifyOperatingStatement({state_id:state_id,month_one_confirm:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formData.month_one_confirm='1'
              this.$message.success("确认成功")
            }
          })
        }else if(month === 2){
          modifyOperatingStatement({state_id:state_id,month_two_confirm:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formData.month_two_confirm='1'
              this.$message.success("确认成功")
            }
          })
        }else if(month === 3){
          modifyOperatingStatement({state_id:state_id,month_three_confirm:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formData.month_three_confirm='1'
              this.$message.success("确认成功")
            }
          })
        }else if(month === 4){
          modifyOperatingStatement({state_id:state_id,month_four_confirm:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formData.month_four_confirm='1'
              this.$message.success("确认成功")
            }
          })
        }else if(month === 5){
          modifyOperatingStatement({state_id:state_id,month_five_confirm:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formData.month_five_confirm='1'
              this.$message.success("确认成功")
            }
          })
        }else if(month === 6){
          modifyOperatingStatement({state_id:state_id,month_six_confirm:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formData.month_six_confirm='1'
              this.$message.success("确认成功")
            }
          })
        }else if(month === 7){
          modifyOperatingStatement({state_id:state_id,month_seven_confirm:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formData.month_seven_confirm='1'
              this.$message.success("确认成功")
            }
          })
        }else if(month === 8){
          modifyOperatingStatement({state_id:state_id,month_eight_confirm:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formData.month_eight_confirm='1'
              this.$message.success("确认成功")
            }
          })
        }else if(month === 9){
          modifyOperatingStatement({state_id:state_id,month_nine_confirm:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formData.month_nine_confirm='1'
              this.$message.success("确认成功")
            }
          })
        }else if(month === 10){
          modifyOperatingStatement({state_id:state_id,month_ten_confirm:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formData.month_ten_confirm='1'
              this.$message.success("确认成功")
            }
          })
        }else if(month === 11){
          modifyOperatingStatement({state_id:state_id,month_eleven_confirm:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formData.month_eleven_confirm='1'
              this.$message.success("确认成功")
            }
          })
        }else if(month === 12){
          modifyOperatingStatement({state_id:state_id,month_twelve_confirm:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.formData.month_twelve_confirm='1'
              this.$message.success("确认成功")
            }
          })
        }
      }).catch(()=>{
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>