<template>
  <div style="margin: -10px -25px -10px -25px">
    <div class="box-container">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepsid" style="width: 20%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="计划年份" prop="year" style="width: 20%">
              <a-select v-model="queryParams.year">
                <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="月份" prop="month" style="width: 20%">
              <a-select v-model="queryParams.month">
                <a-select-option value="1">{{1+'月'}}</a-select-option>
                <a-select-option value="2">{{2+'月'}}</a-select-option>
                <a-select-option value="3">{{3+'月'}}</a-select-option>
                <a-select-option value="4">{{4+'月'}}</a-select-option>
                <a-select-option value="5">{{5+'月'}}</a-select-option>
                <a-select-option value="6">{{6+'月'}}</a-select-option>
                <a-select-option value="7">{{7+'月'}}</a-select-option>
                <a-select-option value="8">{{8+'月'}}</a-select-option>
                <a-select-option value="9">{{9+'月'}}</a-select-option>
                <a-select-option value="10">{{10+'月'}}</a-select-option>
                <a-select-option value="11">{{11+'月'}}</a-select-option>
                <a-select-option value="12">{{12+'月'}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="是否作废" prop="status" style="width: 20%">
              <a-select v-model="queryParams.status">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">否</a-select-option>
                <a-select-option value="1">是</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
            <div v-show="showAdvanced" class="advanced-query">
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
<!--            <a-button v-if="btnList.includes('新增')" @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <div style="width: 100%;">
          <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" row-key="state_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
            <span slot="income_type" slot-scope="value">{{incomeType[value]}}</span>
            <span slot="month_one" slot-scope="value,record"><span :style="record.month_one_confirm?record.month_one_confirm==='1'?{'color':'blue'}:{'color':'red'}:{'color':'red'}" :title="value">{{value}}</span></span>
            <span slot="month_two" slot-scope="value,record"><span :style="record.month_two_confirm?record.month_two_confirm==='1'?{'color':'blue'}:{'color':'red'}:{'color':'red'}" :title="value">{{value}}</span></span>
            <span slot="month_three" slot-scope="value,record"><span :style="record.month_three_confirm?record.month_three_confirm==='1'?{'color':'blue'}:{'color':'red'}:{'color':'red'}" :title="value">{{value}}</span></span>
            <span slot="month_four" slot-scope="value,record"><span :style="record.month_four_confirm?record.month_four_confirm==='1'?{'color':'blue'}:{'color':'red'}:{'color':'red'}" :title="value">{{value}}</span></span>
            <span slot="month_five" slot-scope="value,record"><span :style="record.month_five_confirm?record.month_five_confirm==='1'?{'color':'blue'}:{'color':'red'}:{'color':'red'}" :title="value">{{value}}</span></span>
            <span slot="month_six" slot-scope="value,record"><span :style="record.month_six_confirm?record.month_six_confirm==='1'?{'color':'blue'}:{'color':'red'}:{'color':'red'}" :title="value">{{value}}</span></span>
            <span slot="month_seven" slot-scope="value,record"><span :style="record.month_seven_confirm?record.month_seven_confirm==='1'?{'color':'blue'}:{'color':'red'}:{'color':'red'}" :title="value">{{value}}</span></span>
            <span slot="month_eight" slot-scope="value,record"><span :style="record.month_eight_confirm?record.month_eight_confirm==='1'?{'color':'blue'}:{'color':'red'}:{'color':'red'}" :title="value">{{value}}</span></span>
            <span slot="month_nine" slot-scope="value,record"><span :style="record.month_nine_confirm?record.month_nine_confirm==='1'?{'color':'blue'}:{'color':'red'}:{'color':'red'}" :title="value">{{value}}</span></span>
            <span slot="month_ten" slot-scope="value,record"><span :style="record.month_ten_confirm?record.month_ten_confirm==='1'?{'color':'blue'}:{'color':'red'}:{'color':'red'}" :title="value">{{value}}</span></span>
            <span slot="month_eleven" slot-scope="value,record"><span :style="record.month_eleven_confirm?record.month_eleven_confirm==='1'?{'color':'blue'}:{'color':'red'}:{'color':'red'}" :title="value">{{value}}</span></span>
            <span slot="month_twelve" slot-scope="value,record"><span :style="record.month_twelve_confirm?record.month_twelve_confirm==='1'?{'color':'blue'}:{'color':'red'}:{'color':'red'}" :title="value">{{value}}</span></span>
            <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.state_id">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('修改')" :key="'edit-'+record.state_id">修改</a-menu-item>
<!--                <a-menu-item v-if="btnList.includes('作废')" :key="'delete-'+record.state_id">作废</a-menu-item>-->
              </a-menu>
            </a-dropdown>
          </span>
          </a-table>
          <h3 style="color:red">注意：表格中蓝色字体为已确认的月度数据，红色字体为未确认的数据，未确认的数据将不会在下面图表中显示</h3>
          <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
        <div v-if="queryParams.status==='0'" style="width: 100%;">
          <div class="bar" id="income_bar_left" style="height: 300px;width: 50%;float: left"></div>
          <div class="bar" id="income_bar_right" style="height: 300px;width: 50%;float: right"></div>
        </div>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData(false)"></add-or-edit-modal>
  </div>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey} from "U/index";
import deptselect from "@/mixins/deptselect";
import addOrEditModal from "V/businessManagement/reportForms/operatingStatements/month/income/addOrEditModal";
import {
  deleteOperatingStatement, getOperatingStatementChartsData,
  getOperatingStatementListByCondition,
} from "A/businessmanagement";
import {getMonitorPointNameListByCondition} from "A/monitoring";
import * as echarts from "echarts";
import {incomeType} from "@/json/businessmanagement";
export default {
  name: "income",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      pageTitle:'年度实际收入',
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      incomeType,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        category:'年度实际收入',
        finance_type:'收入',
        decision_type:'实际',
        year:'',
        month:'',
        status:'0',
        is_confirmed:'1',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          width: 140
        },
        {
          title: '收入类型',
          dataIndex: 'income_type',
          key: 'income_type',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'income_type' },
        },
        {
          title: '一月',
          dataIndex: 'month_one',
          key: 'month_one',
          ellipsis: true,
          scopedSlots: { customRender: 'month_one' },
        },
        {
          title: '二月',
          dataIndex: 'month_two',
          key: 'month_two',
          ellipsis: true,
          scopedSlots: { customRender: 'month_two' },
        },
        {
          title: '三月',
          dataIndex: 'month_three',
          key: 'month_three',
          ellipsis: true,
          scopedSlots: { customRender: 'month_three' },
        },
        {
          title: '四月',
          dataIndex: 'month_four',
          key: 'month_four',
          ellipsis: true,
          scopedSlots: { customRender: 'month_four' },
        },
        {
          title: '五月',
          dataIndex: 'month_five',
          key: 'month_five',
          ellipsis: true,
          scopedSlots: { customRender: 'month_five' },
        },
        {
          title: '六月',
          dataIndex: 'month_six',
          key: 'month_six',
          ellipsis: true,
          scopedSlots: { customRender: 'month_six' },
        },
        {
          title: '七月',
          dataIndex: 'month_seven',
          key: 'month_seven',
          ellipsis: true,
          scopedSlots: { customRender: 'month_seven' },
        },
        {
          title: '八月',
          dataIndex: 'month_eight',
          key: 'month_eight',
          ellipsis: true,
          scopedSlots: { customRender: 'month_eight' },
        },
        {
          title: '九月',
          dataIndex: 'month_nine',
          key: 'month_nine',
          ellipsis: true,
          scopedSlots: { customRender: 'month_nine' },
        },
        {
          title: '十月',
          dataIndex: 'month_ten',
          key: 'month_ten',
          ellipsis: true,
          scopedSlots: { customRender: 'month_ten' },
        },
        {
          title: '十一月',
          dataIndex: 'month_eleven',
          key: 'month_eleven',
          ellipsis: true,
          scopedSlots: { customRender: 'month_eleven' },
        },
        {
          title: '十二月',
          dataIndex: 'month_twelve',
          key: 'month_twelve',
          ellipsis: true,
          scopedSlots: { customRender: 'month_twelve' },
        },
        {
          title: '年份',
          dataIndex: 'year',
          key: 'year',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 40
        },
      ],
      userdepidCascaderSelected: [],
      yearOptions: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      monitorpointList:[],

      xDataList1:[],
      xDataList2:[],
      xLabelList:[],
      xDataList3:[],

      xDataList4:[],
      xDataList5:[],
      xLabelList2:[],
      xDataList6:[],

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getYearOptions();
    this.getMonitorPointNameList()
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
      };
      getOperatingStatementChartsData(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.resetEchartsData()
          this.xLabelList=res.label.map((item, index) => {
            if(item==='1'){
              return "物业管理费"
            }else if(item==='2'){
              return "车位管理费"
            }else if(item==='3'){
              return "产权车场收入"
            }else if(item==='4'){
              return "公共收入"
            }else if(item==='5'){
              return "多经收入"
            }
          })
          this.xDataList1=res.plan
          this.xDataList2=res.actual
          this.xDataList3=res.rate

          this.xLabelList2=res.cumulativeLabel.map((item, index) => {
            if(item==='1'){
              return "物业管理费"
            }else if(item==='2'){
              return "车位管理费"
            }else if(item==='3'){
              return "产权车场收入"
            }else if(item==='4'){
              return "公共收入"
            }else if(item==='5'){
              return "多经收入"
            }
          })

          this.xDataList4=res.cumulativePlan
          this.xDataList5=res.cumulativeActual
          this.xDataList6=res.cumulativeRate
          let that=this
          setTimeout(()=>{
            that.initCharts1()
            that.initCharts2()
          },500)
          getOperatingStatementListByCondition(params).then(res => {
            this.tableLoading = false;
            if(res && res.returncode == '0') {
              this.tableData = res.item;
              this.pagination.total = res.count;
            }
          }).catch(err => {
            this.tableLoading = false;
          })
        }
      })
    },
    initCharts1(){
      let chartDom = document.getElementById('income_bar_left');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title:{
          text:"当月完成情况"
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#e20937'
            }
          }
        },
        toolbox: {
          feature: {
          },
          iconStyle:{
            normal:{
              color:'#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: 'category',
            data: this.xLabelList,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              show: true,
              formatter: function (value) {
                let ret = "";//拼接加\n返回的类目项
                let maxLength = 5;//每项显示文字个数
                let valLength = value.length;//X轴类目项的文字个数
                let rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1)//如果类目项的文字大于5
                {
                  for (let i = 0; i < rowN; i++) {
                    let temp = "";//每次截取的字符串
                    let start = i * maxLength;//开始截取的位置
                    let end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                } else {
                  return value;
                }
              },
            }
          }
        ],
        yAxis: [
          {
            show:true,
            type: 'value',
            axisLabel: {
              formatter: '{value} 元',
              textStyle:{
                fontSize: 8,
              }
            }
          },
          // {
          //   type: 'value',
          //   nameTextStyle: {color: '#ffffff'},
          //   min: 0,
          //   max: 100,
          //   interval: 20,
          //   axisLabel: {
          //     formatter: '{value} %',
          //     textStyle: {
          //       color: '#000000',
          //       fontSize: 15,
          //       fontWeight: 'bold',
          //     }
          //   },
          //   splitArea : {show : false},  //去除网格区域
          //   splitLine: {//去除网格线
          //     show: false,
          //   }
          // }
        ],
        series: [
          {
            name: '月度预算数',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元';
              }
            },
            label: {
              show: true,
              position: 'top', // 在上方显示
              distance: 10, // 距离图形元素的距离，当 position 为字符描述值（如 'top'、'insideRight'）时候有效
              verticalAlign: 'middle', // 垂直对齐方式
              textStyle: { // 数值样式
                color: '#000', // 文本颜色
                fontSize: 12 // 字体大小
              }
            },
            data: this.xDataList1,
            itemStyle:{
              color:'#1482db',
            },
          },
          {
            name: this.queryParams.month+'月执行数',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元';
              }
            },
            label: {
              show: true,
              position: 'top', // 在上方显示
              distance: 10, // 距离图形元素的距离，当 position 为字符描述值（如 'top'、'insideRight'）时候有效
              verticalAlign: 'middle', // 垂直对齐方式
              textStyle: { // 数值样式
                color: '#000', // 文本颜色
                fontSize: 12 // 字体大小
              }
            },
            data: this.xDataList2,
            itemStyle:{
              color:'#820871',
            },
          },
          // {
          //   name: '实际计划占比',
          //   type: 'scatter',
          //   yAxisIndex: 1,
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       return value + ' %';
          //     }
          //   },
          //   data: this.xDataList3,
          // }
        ]
      };
      option && myChart.setOption(option);
    },
    initCharts2(){
      let chartDom = document.getElementById('income_bar_right');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title:{
          text:"当期完成情况"
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#e20937'
            }
          }
        },
        toolbox: {
          feature: {
          },
          iconStyle:{
            normal:{
              color:'#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: 'category',
            data: this.xLabelList2,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              show: true,
              formatter: function (value) {
                let ret = "";//拼接加\n返回的类目项
                let maxLength = 5;//每项显示文字个数
                let valLength = value.length;//X轴类目项的文字个数
                let rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1)//如果类目项的文字大于5
                {
                  for (let i = 0; i < rowN; i++) {
                    let temp = "";//每次截取的字符串
                    let start = i * maxLength;//开始截取的位置
                    let end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                } else {
                  return value;
                }
              },
            }
          }
        ],
        yAxis: [
          {
            show:true,
            type: 'value',
            axisLabel: {
              formatter: '{value} 元',
              textStyle:{
                fontSize: 8,
              }
            }
          },
          // {
          //   type: 'value',
          //   nameTextStyle: {color: '#ffffff'},
          //   min: 0,
          //   max: 100,
          //   interval: 20,
          //   axisLabel: {
          //     formatter: '{value} %',
          //     textStyle: {
          //       color: '#000000',
          //       fontSize: 15,
          //       fontWeight: 'bold',
          //     }
          //   },
          //   splitArea : {show : false},  //去除网格区域
          //   splitLine: {//去除网格线
          //     show: false,
          //   }
          // }
        ],
        series: [
          {
            name: '月度预算数',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元';
              }
            },
            label: {
              show: true,
              position: 'top', // 在上方显示
              distance: 10, // 距离图形元素的距离，当 position 为字符描述值（如 'top'、'insideRight'）时候有效
              verticalAlign: 'middle', // 垂直对齐方式
              textStyle: { // 数值样式
                color: '#000', // 文本颜色
                fontSize: 12 // 字体大小
              }
            },
            data: this.xDataList4,
            itemStyle:{
              color:'#1482db',
            },
          },
          {
            name: '月度执行数',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元';
              }
            },
            label: {
              show: true,
              position: 'top', // 在上方显示
              distance: 10, // 距离图形元素的距离，当 position 为字符描述值（如 'top'、'insideRight'）时候有效
              verticalAlign: 'middle', // 垂直对齐方式
              textStyle: { // 数值样式
                color: '#000', // 文本颜色
                fontSize: 12 // 字体大小
              }
            },
            data: this.xDataList5,
            itemStyle:{
              color:'#820871',
            },
          },
          // {
          //   name: '实际计划占比',
          //   type: 'scatter',
          //   yAxisIndex: 1,
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       return value + ' %';
          //     }
          //   },
          //   data: this.xDataList3,
          // }
        ]
      };
      option && myChart.setOption(option);
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let state_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'state_id', state_id);
      if(type == 'delete') {
        this.deleteConfirm(state_id, record)
      }else{
        this.showModal(type,record)
      }
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      this.getYearOptions()
    },
    resetEchartsData(){
      this.xDataList1=[]
      this.xDataList2=[]
      this.xDataList3=[]

      this.xDataList4=[]
      this.xDataList5=[]
      this.xDataList6=[]
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalDetailData.btnList=this.btnList
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalDetailData.btnList=this.btnList
        this.modalVisible=true
      }
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType='detail'
            this.modalDetailData.btnList=this.btnList
            this.modalVisible=true
          },
        },
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.state_id);
      }).catch(()=>{

      }) ;
    },
    delete(state_id) {
      this.showLoading();
      if(state_id) {
        let params = {
          state_id
        };
        deleteOperatingStatement(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      let lastYear = moment().year()-1;
      for(let i=1; i>-1; i--) {
        this.yearOptions.push(currentYear+i+'');
      }
      this.yearOptions.push(lastYear+'')
      this.queryParams.year=currentYear+''
      this.queryParams.month=moment().month()+1+''
    },
  },
}
</script>
<style scoped>

</style>